import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type ResubscribeSessionInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


type ResubscribeSessionInfoQueryPayload = { __typename?: 'Query', currentUser: { __typename?: 'UserPayload', data: { __typename?: 'UserData', purchaseSessionInfo: { __typename?: 'PurchaseSessionInfo', lastResubscribeSessionIdInProgress: number | null } | null } | null } };


 const ResubscribeSessionInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResubscribeSessionInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseSessionInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastResubscribeSessionIdInProgress"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ResubscribeSessionInfoQueryPayload, ResubscribeSessionInfoQueryVariables>;


export type ResubscribeSessionInfoVariables = ResubscribeSessionInfoQueryVariables;
export type ResubscribeSessionInfoPayload = ResubscribeSessionInfoQueryPayload;

// remove fragments duplicates
// @ts-ignore
ResubscribeSessionInfoDocument['definitions'] = [ ...new Set(ResubscribeSessionInfoDocument.definitions) ];

export default ResubscribeSessionInfoDocument;