import { useEffect, useState } from 'react'
import cookieStorage from 'cookie-storage'
import { usePathname, useSearchParams } from 'router'
import { useFt } from 'hooks'
import { constants } from 'helpers'
import { useIntl } from 'intl'
import links from 'links'
import { useCouponData } from 'modules/coupon'
import { useResubscribeOrigin } from './useResubscribeOrigin'


export type ResubscribeOffer = typeof offers[number]
export type EventResubscribeOffer = typeof eventOffers[ResubscribeOffer]

// all available offers
const offers = [
  'discount',
  'off50',
  'off60',
  'freeProduct',
  'freeCase',
  'freeMonth',
  'noDiscount',
] as const

// rotation of offers in regular resubscribe flow
const offersRotation: ResubscribeOffer[] = [
  'discount',
  'off50',
  'freeProduct',
  'freeCase',
  'freeMonth',
  'noDiscount',
]

const eventOffers = {
  'discount': '$6.95 off',
  'off50': '50% off',
  'off60': '60% off',
  'freeProduct': 'Free product',
  'freeCase': 'Free case',
  'freeMonth': 'Free month',
  'noDiscount': 'No offer',
}

const coupons: Partial<Record<ResubscribeOffer, string>> = {
  discount: 'RESUBNEW',
  off50: 'RESUB50',
  off60: 'RESUB60',
  freeProduct: 'RESUBFREEPRODUCT',
  freeCase: 'RESUBFREECASE',
  freeMonth: 'RESUBFREE',
}

const pathToOffer: Record<string, ResubscribeOffer> = {
  [links.resubscribeNoOffer]: 'noDiscount',
  [links.resubscribe50]: 'off50',
  [links.resubscribe60]: 'off60',
  [links.resubscribeFree]: 'freeMonth',
  [links.resubscribeFreeProduct]: 'freeProduct',
  [links.resubscribeFreeCase]: 'freeCase',
}

// returns info about an offer
export const useResubscribeOfferData = (offer: ResubscribeOffer) => {
  const couponCode = coupons[offer]

  const intl = useIntl()
  const origin = useResubscribeOrigin()

  const { couponData, isCouponDataFetching, isCouponUnavailable } = useCouponData({
    couponCode,
    origin,
  })

  const freeProduct = couponData?.freeProduct

  const discountAmount = couponData?.discountAmount
  const formattedDiscountAmount = discountAmount ? intl.formatPrice(discountAmount) : null
  const discountPercentage = couponData?.discountPercentage
  const eventOffer: EventResubscribeOffer = offer === 'discount' ? `${formattedDiscountAmount} off` : eventOffers[offer]

  const giftSubscriptionOfferCookie = cookieStorage.getItem(constants.cookieNames.giftSubscriptionOffer)
  const giftSubscriptionOffer = giftSubscriptionOfferCookie && parseInt(giftSubscriptionOfferCookie, 10)

  const isHoliday22Resubscribe60 = useFt(constants.features.holiday22Resubscribe60)
  const isRestrictedCoupon = offer === 'off60' && !isHoliday22Resubscribe60

  const isAvailable = !isRestrictedCoupon && (!couponCode || Boolean(couponData))
  const isUnavailable = isRestrictedCoupon || (couponCode ? isCouponUnavailable : false)

  return {
    offer,
    giftSubscriptionOffer,
    eventOffer,
    couponCode,
    freeProduct,
    discountAmount,
    formattedDiscountAmount,
    discountPercentage,
    isFetching: isCouponDataFetching,
    isAvailable,
    isUnavailable,
  }
}

export const useResubscribeOffer = (params?: { forceOffer?: ResubscribeOffer, withRotation?: boolean }) => {
  // offer will be the first offer to check, if withRotation is true, force offer will be replaced when unavailable
  const { forceOffer, withRotation = true } = params || {}

  // we can redefine default value by search param
  const [ { forceOffer: queryOffer } ] = useSearchParams<{ forceOffer?: ResubscribeOffer }>()
  const pathname = usePathname()

  const pathOffer = pathToOffer[pathname]

  const [ offer, setOffer ] = useState<ResubscribeOffer>(() => {
    if (forceOffer && offers.includes(forceOffer)) {
      cookieStorage.setItem(constants.cookieNames.resubscribeOffer, forceOffer)
      return forceOffer
    }

    // offer search param has the highest priority, but it should be one of the predefined offers
    if (queryOffer && offers.includes(queryOffer)) {
      return queryOffer
    }

    // offers from landing pages
    if (pathOffer && offers.includes(pathOffer)) {
      return pathOffer
    }

    // then check previously saved offer
    const cookieOffer = cookieStorage.getItem(constants.cookieNames.resubscribeOffer)
    if (cookieOffer && offers.includes(cookieOffer)) {
      return cookieOffer
    }

    // otherwise, first offer from the list
    return offersRotation[0]
  })

  const offerData = useResubscribeOfferData(offer)
  const { isUnavailable, isAvailable } = offerData

  const offerIndex = offersRotation.indexOf(offer)

  const needNextOffer = (
    withRotation
    && isUnavailable
    && offerIndex < (offersRotation.length - 1)
  )

  useEffect(() => {
    if (needNextOffer) {
      const nextOffer = offersRotation[offerIndex + 1] || 'noDiscount'
      setOffer(nextOffer)
    }
  }, [ offerIndex, needNextOffer ])

  useEffect(() => {
    // save offer to a cookie to keep the same offer across the website
    if (offer && isAvailable) {
      cookieStorage.setSessionItem(constants.cookieNames.resubscribeOffer, offer)
    }
  }, [ offer, isAvailable ])

  return {
    ...offerData,
    isFetching: offerData.isFetching || needNextOffer,
  }
}
