import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'
import { type OriginType } from 'typings/graphql'


export const useResubscribeOrigin = (): OriginType => {
  const isFromEmail = cookieStorage.getItem(constants.cookieNames.fromEmail)

  return isFromEmail ? 'EMAIL' : 'SITE'
}
