import { useCallback } from 'react'
import { useApolloClient } from 'apollo-client'
import { dataLayer } from 'analytics'
import { GraphQLError, ServerError, UserError } from 'helpers'
import { useResetCouponsCache } from 'modules/coupon'
import { useResubscribeOrigin } from './useResubscribeOrigin'

import resubscribeQuery, { type ResubscribeVariables } from './graph/resubscribe.graphql'
import resubscribeSessionQuery from './graph/resubscribeSessionInfo.graphql'
import waitForResubscribeQuery, { type WaitForResubscribePayload, type WaitForResubscribeVariables } from './graph/waitForResubscribe.graphql'


const useResubscribeMutation = () => {
  const apolloClient = useApolloClient()

  const resubscribeMutation = useCallback(async (input: ResubscribeVariables['input']) => {
    const result = await apolloClient.mutate({
      mutation: resubscribeQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const {
      data,
      error,
    } = result.data.subscriptionResubscribe

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ apolloClient ])

  const getResubscribeSessionId = useCallback(async () => {
    const result = await apolloClient.query({
      query: resubscribeSessionQuery,
      fetchPolicy: 'network-only',
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const sessionId = result.data.currentUser?.data?.purchaseSessionInfo?.lastResubscribeSessionIdInProgress

    if (!sessionId) {
      throw new ServerError(null, 'Resubscribe session not found')
    }

    return sessionId
  }, [ apolloClient ])

  const waitForResubscribe = useCallback((input: WaitForResubscribeVariables['input']) => {
    return new Promise<WaitForResubscribePayload['subscriptionResubscribeSession']>((resolve, reject) => {
      const observableQuery = apolloClient.subscribe({
        query: waitForResubscribeQuery,
        fetchPolicy: 'network-only',
        variables: {
          input,
        },
        context: {
          webSocket: true,
        },
      })

      let subscription = observableQuery.subscribe({
        next: ({ data }) => {
          const {
            session,
            error,
          } = data.subscriptionResubscribeSession || {}

          if (session?.status === 'SUCCESS') {
            endSubscription()
            resolve(data.subscriptionResubscribeSession)
          }
          else if (session?.status === 'FAILED' || !session && error) {
            endSubscription()
            reject(new UserError(error, error?.message || 'Wait for resubscribe error'))
          }
        },
        error: (error) => {
          endSubscription()
          reject(new ServerError(error))
        },
        complete: () => {
          endSubscription()
        },
      })

      const endSubscription = () => {
        if (subscription) {
          subscription.unsubscribe()
          subscription = null
        }
      }
    })
  }, [ apolloClient ])

  return useCallback(async (input: ResubscribeVariables['input']) => {
    try {
      return await resubscribeMutation(input)
    }
    catch (error) {
      const isPostponedPurchase = (
        // if it's a gateway timeout error, then we start waiting for the commit
        error?.networkError?.statusCode === 504
        || error?.cause?.purchaseErrorCode === 'EXTERNAL_NETWORK_ISSUE'
        || error?.cause?.purchaseErrorCode === 'PURCHASE_IN_PROGRESS'
      )

      if (isPostponedPurchase) {
        const sessionId = await getResubscribeSessionId()
        return await waitForResubscribe({ sessionId })
      }

      throw error
    }
  }, [ resubscribeMutation, getResubscribeSessionId, waitForResubscribe ])
}

type ResubscribeMetadata = {
  amount?: number
}

export const useResubscribe = () => {
  const origin = useResubscribeOrigin()

  const apolloClient = useApolloClient()
  const resubscribeMutation = useResubscribeMutation()
  const resetCouponsCache = useResetCouponsCache()

  return useCallback(async (input?: ResubscribeVariables['input'], metadata?: ResubscribeMetadata) => {
    try {
      const data = await resubscribeMutation({
        ...input,
        origin,
      })

      const order = data?.__typename === 'ResubscribeData' ? data?.order : data?.data?.order
      const subscriptionOrderItem = order?.items?.find((item) => item.__typename === 'SubscriptionPurchaseItem')

      dataLayer.track('resubscribe', {
        resubscribePath: 'from one click',
        coupon: input.couponCode || null,
        subscription_id: subscriptionOrderItem?.id || null,
        charge_amount: metadata?.amount || null,
      })

      return data
    }
    finally {
      // reset queue cache, because plan can change
      apolloClient.cache.evict({
        id: 'UserData:{}',
        fieldName: 'queue',
      })

      await apolloClient.refetchQueries({
        include: [ 'Subscription' ],
      })

      // ATTN we should reset coupons cache to refetch all coupons info
      resetCouponsCache()
    }
  }, [ apolloClient, origin, resubscribeMutation, resetCouponsCache ])
}
